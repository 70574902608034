import * as React       from "react";
import {Main}           from "../Main";
import {Form}           from "react-bootstrap";
import {ModalContainer} from "./ModalContainer";

interface IServerStatus
{
	label: string;
	running: boolean;
	ready: boolean;
	poolId: number;
	jobCount: number;
	url: string;
}

interface IClusterStatus
{
	exportServers: IServerStatus[];
	renderServers: IServerStatus[];
	maintenanceMode: boolean;
}

interface IServersProps
{
	summary?: boolean;
}

interface IServersStates
{
	console: string;
	servers: IClusterStatus,
	showRenderEvents: boolean,
	showAWSEvents: boolean,
	showLoadBalancing: boolean,
	highlightedRender: string
}

export class Servers extends React.PureComponent<IServersProps, IServersStates>
{
	static defaultProps = {
		summary: false
	};

	public state: IServersStates = {
		console: '',
		servers: undefined,
		showRenderEvents: true,
		showAWSEvents: true,
		showLoadBalancing: true,
		highlightedRender: ""
	};

	public componentDidMount = async () =>
	{
		const consoleRaw = await fetch('console');
		const console = await consoleRaw.text();

		const serversRaw = await fetch('awsStatus');
		const servers = await serversRaw.json();

		this.setState({console, servers});

		Main.signals.console.add(this.onConsoleEvent);
		Main.signals.servers.add(this.onServersEvent);
	};

	private onConsoleEvent = (message: string) =>
	{
		this.setState({
			console: `${message}\n${this.state.console}`
		});
	};

	private onServersEvent = (status: IClusterStatus) =>
	{
		this.setState({
			servers: status
		});
	};

	private formatServerStatus(server: IServerStatus)
	{
		let status = "stopped";
		if (server.label.includes("LOCAL"))
		{
			status = (server.jobCount) ? "running" : "waiting";
		}
		else if (server.running)
		{
			status = (server.ready) ? "running" : "connecting";
		}
		return status;
	}

	private sortServers(s1: IServerStatus, s2: IServerStatus)
	{
		return s1.label.localeCompare(s2.label);
	}

	private formatServer = (server: IServerStatus) =>
	{
		const maintenance = this.state.servers.maintenanceMode;
		const status = this.formatServerStatus(server);
		return <div className={maintenance ? "maintenance" : ""}>
			<h2>
				{server.label}
			</h2>
			<table>
				<tr>
					<td colSpan={2} className={(status === "running" ? "running" : "")}>{status}</td>
				</tr>
				{
					server.poolId !== undefined &&
					<tr>
						<td>Pool</td>
						<td>{server.poolId}</td>
					</tr>
				}
				<tr>
					<td>Jobs</td>
					<td>{server.jobCount}</td>
				</tr>
				{maintenance && status === "stopped" &&
					<tr>
						<td colSpan={2}>
							<button onClick={() => fetch(`awsForceStart/${server.label}`)}>
								Force start
							</button>
						</td>
					</tr>
				}
				{maintenance && status !== "stopped" &&
					<tr>
						<td colSpan={2}>
							<button onClick={() => fetch(`awsForceStop/${server.label}`)}
							>
								Force stop
							</button>
						</td>
					</tr>
				}
			</table>
		</div>;
	};

	private get console()
	{
		const {showRenderEvents, showAWSEvents, showLoadBalancing, highlightedRender} = this.state;

		const lines = this.state.console.split('\n');
		const output = [];
		for (const line of lines)
		{
			// first word is the event type that will define the coloring for this message
			const parts = line.split(" ");
			const eventType = parts.shift().toLowerCase();
			let uid = "";
			if (eventType === "render")
			{
				// replace inline control characters with spans for separately colored uids in render messages
				uid = parts[2].replace("\x1b[32m", "").replace("\x1b[0m", "");
				parts[2] = `<span>${uid}</span>`;
			}
			if (eventType === "render" && !showRenderEvents ||
				eventType === "aws" && !showAWSEvents ||
				eventType === "loadbalancer" && !showLoadBalancing)
			{
				continue;
			}
			output.push(<span
				className={eventType}
				dangerouslySetInnerHTML={{ __html: parts.join(" ")}}
				{...(uid && {
					'data-highlighted': highlightedRender === uid,
					onClick: () => this.setState({
						highlightedRender: (highlightedRender === uid) ? "" : uid
					}),
				})}
			/>);
		}
		return output;
	}

	private readonly toggleMaintenance = async () =>
	{
		const command = (this.state.servers.maintenanceMode) ? "exit" : "enter";
		await fetch(`maintenance/${command}`);
	};

	private readonly cleanup = async () =>
	{
		if (confirm("Are you sure?"))
		{
			const response = await fetch(`cleanup`);
			const result = await response.text();
			Main.showModal(<ModalContainer title="Cleanup result" size={"lg"}>
				<pre>{result}</pre>
			</ModalContainer>);
		}
	};

	private readonly e2e = () =>
	{
		if (confirm("Are you sure?"))
		{
			fetch(`e2e/init`);
		}
	};

	public render()
	{
		const {summary} = this.props;
		const {servers, showRenderEvents, showAWSEvents, showLoadBalancing} = this.state;

		if (!servers)
		{
			return <div id={"console"}>Fetching data...</div>;
		}

		return <>
			<div className={"servers"}>
				{servers.exportServers.sort(this.sortServers).map(this.formatServer)}
			</div>
			<div className={"servers"}>
				{servers.renderServers.sort(this.sortServers).map(this.formatServer)}
			</div>
			<div id={"console"} className={(summary) ? "summary" : ""}>
				<div id={"maintenance"} className={servers.maintenanceMode ? "active" : ""}
				     onClick={this.toggleMaintenance}>
					{servers.maintenanceMode ? "Exit" : "Enter"} maintenance mode
				</div>
				<div id={"cleanup"} onClick={this.cleanup}>
					Cleanup old folders
				</div>
				<div id={"e2e"} onClick={this.e2e}>
					Run E2E test suite
				</div>
				<div id={"filters"}>
					<strong>Filters</strong>
					<Form.Check
						inline label="Render events" type='switch'
						id="console-filters-1"
						checked={showRenderEvents}
						onChange={() => this.setState({showRenderEvents: !showRenderEvents})}
					/>
					<Form.Check
						inline label="AWS events" type='switch'
						id="console-filters-2"
						checked={showAWSEvents}
						onChange={() => this.setState({showAWSEvents: !showAWSEvents})}
					/>
					<Form.Check
						inline label="Load balancing" type='switch'
						id="console-filters-3"
						checked={showLoadBalancing}
						onChange={() => this.setState({showLoadBalancing: !showLoadBalancing})}
					/>
				</div>
				<pre className={servers.maintenanceMode ? "maintenance" : ""}>
					{this.console}
				</pre>
			</div>
		</>;
	}
}
