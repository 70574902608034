export class Signal
{
	private readonly callbacks: Array<(data: any) => void> = [];

	public add(fn: (data: any) => void)
	{
		this.callbacks.push(fn);
	}

	public remove(fn: (data: any) => void)
	{
		const index = this.callbacks.indexOf(fn);
		if (index > -1)
		{
			this.callbacks.splice(index, 1);
		}
	}

	public dispatch(data: any)
	{
		this.callbacks.forEach(fn => fn(data));
	}
}
