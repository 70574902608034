import * as React     from "react";
import {E2ETestSuite} from "../../common/utils";
import {TestChart}    from "./TestChart";

interface ITestDetailsProps
{
	suite: E2ETestSuite;
	idx: number;
}

interface ITestDetailsStates
{
	log: string;
}

export class TestDetails extends React.PureComponent<ITestDetailsProps, ITestDetailsStates>
{
	public state = {
		log: ""
	};

	public componentDidMount = async () =>
	{
		const {suite, idx} = this.props;
		const ts = suite.timestamp;
		const label = suite.tests[idx].label;
		const response = await fetch(`e2e/fetchLog/${ts}/${label}`);
		const log = await response.text();
		this.setState({log});
	};

	public render()
	{
		const {suite, idx} = this.props;
		const ts = suite.timestamp;
		const test = suite.tests[idx];
		return <div className="test-result">
			{test.status === "done" && <>
                <img src={`e2e/fetchRender/${ts}/${test.label}`}/>
                {this.state.log && <TestChart log={this.state.log}/>}
            </>}
			{test.status !== "done" && <pre>{this.state.log}</pre>}
		</div>;
	}
}
