import * as React       from "react";
import {Carousel}       from "react-bootstrap";
import {ModalContainer} from "../ModalContainer";

export interface IImageModalProps
{
	renderId: string;
}

interface IImageModalStates
{
	loaded: boolean;
	isBatch?: boolean;
	labels?: string[];
}

export class ImageModal extends React.PureComponent<IImageModalProps, IImageModalStates>
{
	public state = {
		loaded: false,
		isBatch: undefined,
		labels: []
	};

	public componentDidMount = async () =>
	{
		const {renderId} = this.props;

		const isBatchResponse = await fetch('isBatch/' + renderId);
		const isBatchText = await isBatchResponse.text();
		const isBatch = isBatchText === "true";

		let labels = [];
		if (isBatch)
		{
			const response = await fetch('labels/' + renderId);
			labels = await response.json();
		}

		this.setState({loaded: true, isBatch, labels});
	};

	public render(): React.ReactNode
	{
		const {renderId} = this.props;
		const {loaded, isBatch, labels} = this.state;
		return (loaded &&
			<ModalContainer title={"Render result"} size="xl" cls="image-modal">
				{isBatch &&
					<Carousel>
						{labels.map((label, idx) =>
							<Carousel.Item key={idx}>
								<img src={`/get/${renderId}?id=${idx + 1}`} alt={label}/>
								{isBatch && <Carousel.Caption>
									<p>{label}</p>
									<p className="image-caption">
										<a href={`/get/${renderId}?id=${idx + 1}`}>Download</a>
									</p>
								</Carousel.Caption>}
							</Carousel.Item>
						)}
					</Carousel>
				}
				{!isBatch &&
					<div className="image-wrapper">
						<img src={`/get/${renderId}`} alt={"result"}/>
						<p className="image-caption">
							<a href={`/get/${renderId}`}>Download</a>
						</p>
					</div>
				}
			</ModalContainer>
		);
	}
}
