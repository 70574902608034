import * as React      from "react";
import {Button, Modal} from "react-bootstrap";
import {Main}          from "../Main";

export interface IModalContainerProps
{
	title: string;
	cls?: string;
	closeButton?: boolean;
	size?: "sm" | "lg" | "xl";
	footerButtonTitle?: string;
	footerButtonAction?: () => void;
}

export class ModalContainer extends React.Component<IModalContainerProps>
{
	static defaultProps = {
		closeButton: true,
		size: undefined,
		footerButtonTitle: undefined,
		footerButtonAction: () => null,
	};

	private close = () => Main.hideModal();

	public render(): React.ReactNode
	{
		const {title, cls, closeButton, size, footerButtonTitle, footerButtonAction, children} = this.props;
		return (
			<Modal dialogClassName={cls} size={size} show={true} onHide={this.close} centered backdrop="static" scrollable>
				<Modal.Header closeButton={closeButton}>
					<strong>{title}</strong>
				</Modal.Header>
				<Modal.Body>
					{children}
				</Modal.Body>
				{
					footerButtonTitle &&
					<Modal.Footer>
						<Button variant="primary" onClick={footerButtonAction}>
							{footerButtonTitle}
						</Button>
					</Modal.Footer>
				}
			</Modal>
		);
	}
}
