import * as React                      from "react";
import {Button, Container, Nav, Table} from "react-bootstrap";
import Pagination                      from "react-js-pagination";

import {IRenderStatus}  from "../../common/utils";
import {Main}           from "../Main";
import {ModalContainer} from "./ModalContainer";
import {ImageModal}     from "./modals/ImageModal";

interface IRendersProps
{
	maxItems: number;
	renders: IRenderStatus[];
}

interface IRendersStates
{
	filter: string;
	activePage: number;
}

export class Renders extends React.PureComponent<IRendersProps, IRendersStates>
{
	public state = {
		filter: "all",
		activePage: 1
	};

	private readonly filters = {
		"all": (item: IRenderStatus) => item,
		"active": (item: IRenderStatus) => item.status !== "done" && item.status !== "error",
		"done": (item: IRenderStatus) => item.status === "done",
		"failed": (item: IRenderStatus) => item.status === "error"
	};

	private showExportLog = async (renderId: string) =>
	{
		const response = await fetch(`exportLog/${renderId}`);
		const log = await response.text();
		const trimmedLog = log.split('\n').filter(line => line !== '\n').join('\n');
		Main.showModal(<ModalContainer title="Export log" size={"xl"}>
			<pre>{trimmedLog}</pre>
		</ModalContainer>);
	};

	private showExportValidation = async (renderId: string) =>
	{
		const response = await fetch(`exportValidation/${renderId}`);
		const report = await response.text();
		Main.showModal(<ModalContainer title="Export validation report" size={"xl"}>
			<pre>{report}</pre>
		</ModalContainer>);
	};

	private showLog = async (renderId: string) =>
	{
		const response = await fetch(`log/${renderId}`);
		const log = await response.text();
		Main.showModal(<ModalContainer title="Log" size={"lg"}>
			<pre>{log}</pre>
		</ModalContainer>);
	};

	private showActions = async (renderId: string) =>
	{
		Main.showModal(<ModalContainer title="Actions">
			<div className={"link"} onClick={() => this.restart(renderId)}>Restart render</div>
			<div className={"link"} onClick={() => this.showExportLog(renderId)}>Show export log</div>
			<div className={"link"} onClick={() => this.showExportValidation(renderId)}>Show GLTF validation report</div>
			<a className={"link"} href={`loaderParams/${renderId}`} target={"_blank"}>Fetch loader params</a>
			<a className={"link"} href={`userParams/${renderId}`} target={"_blank"}>Fetch user-defined params</a>
			<a className={"link"} href={`getIVM/${renderId}`} target={"_blank"}>Fetch scene IVM file</a>
		</ModalContainer>);
	};

	private restart = async (renderId: string) =>
	{
		if (confirm("Are you sure?"))
		{
			await fetch(`restart/${renderId}`);
			Main.hideModal();
		}
	};

	private showImage = async (renderId: string) =>
	{
		Main.showModal(<ImageModal renderId={renderId}/>);
	};

	private createButton(render: IRenderStatus)
	{
		let variant = "info";
		let onclick = () => this.showLog(render.renderId);
		let text = render.status;
		switch (render.status)
		{
			case "done":
				variant = "success";
				if (!render.sdvr)
				{
					onclick = () => this.showImage(render.renderId);
				}
				else
				{
					text = "done (VR)";
				}
				break;
			case "error":
				variant = "danger";
				break;
		}
		return <Button size="sm" variant={variant as any} onClick={onclick}>{text}</Button>;
	}

	private selectFilter = (filter: string) =>
	{
		this.setState({
			filter,
			activePage: 1
		});
	};

	private handlePageChange = (activePage: number) =>
	{
		this.setState({activePage});
	};

	private formatKey(key: string)
	{
		const label = key.toUpperCase();
		const count = this.props.renders.filter(this.filters[key]).length;
		return `${label} (${count})`;
	};

	private formatProvider(provider = "")
	{
		if (provider.includes("vifp.com")) return "fpo";
		return provider
			.replace("www.", "")
			.replace(".com", "")
			.replace(".net", "")
			.split(".")[0];
	}

	public render()
	{
		const {maxItems, renders} = this.props;
		const {filter, activePage} = this.state;
		const filteredRenders = [...renders].sort((a, b) => (
			(a.created < b.created) ? 1 : ((b.created < a.created) ? -1 : 0)
		)).filter(this.filters[filter]);
		const paginationNeeded = filteredRenders.length > maxItems;
		let start = 0;
		let end = maxItems;
		if (paginationNeeded)
		{
			start = (activePage - 1) * maxItems;
			end = Math.min(filteredRenders.length, activePage * maxItems);
		}
		const shownRenders = paginationNeeded ? filteredRenders.slice(start, end) : filteredRenders;
		return <>
			<Container className="container-flex">
				<Nav variant="pills" defaultActiveKey={filter} onSelect={key => this.selectFilter(key)}
				     className="inverted pull-left">
					{Object.keys(this.filters).map((key: string, idx) =>
						<Nav.Item key={idx}>
							<Nav.Link eventKey={key}>{this.formatKey(key)}</Nav.Link>
						</Nav.Item>
					)}
				</Nav>
				{
					paginationNeeded &&
					<Pagination
						activePage={activePage}
						itemsCountPerPage={maxItems}
						totalItemsCount={filteredRenders.length}
						pageRangeDisplayed={5}
						prevPageText="<"
						nextPageText=">"
						itemClass="page-item"
						linkClass="page-link"
						onChange={this.handlePageChange.bind(this)}
					/>
				}
			</Container>
			<div className="spacer"/>
			<Table striped hover>
				<thead className="thead-dark">
				<tr>
					<th style={{"width": "14%"}}>UID</th>
					<th style={{"width": "20%"}}>Origin</th>
					<th style={{"width": "24%"}}>User</th>
					<th style={{"width": "16%"}}>Started</th>
					<th style={{"width": "8%"}}>Log</th>
					<th style={{"width": "8%"}}>Actions</th>
					<th style={{"width": "10%"}} className="text-center">Status</th>
				</tr>
				</thead>
				<tbody>
				{shownRenders.map((render, idx) =>
					<tr key={idx}>
						<td title={render.renderId}>{render.renderId.substring(0, 16)}</td>
						<td>{this.formatProvider(render.provider)}</td>
						<td>{(render.userKey.length > 24) ? render.userKey.substring(0, 23) : render.userKey}</td>
						<td>{render.created.replace(/T/, ' ').replace(/\..+/, '')}</td>
						<td>
							<span className="link" onClick={() => this.showLog(render.renderId)}>show</span>
						</td>
						<td>
							<span className="link" onClick={() => this.showActions(render.renderId)}>show</span>
						</td>
						<td>{this.createButton(render)}</td>
					</tr>)}
				{shownRenders.length === 0 && <td colSpan={8}>Nothing to show...</td>}
				</tbody>
			</Table>
		</>;
	}
}
