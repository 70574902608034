import * as React               from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";
import {IRenderStatus}          from "../common/utils";

import {App}        from './App';
import {ErrorModal} from "./components/modals/ErrorModal";
import {Overview}   from "./components/Overview";
import {Renders}    from "./components/Renders";
import {Servers}    from "./components/Servers";
import {Stats}      from "./components/Stats";
import {Tests}      from "./components/Tests";
import {Signal}     from "./Signal";

export interface IMainProps
{
	app: App;
}

export interface IMainStates
{
	modals: React.ReactNode[];
	renders: IRenderStatus[];
	activePanel: string;
}

export class Main extends React.Component<IMainProps, IMainStates>
{
	public static signals = {
		console: new Signal(),
		render: new Signal(),
		servers: new Signal()
	};

	private static instance: Main;

	public static initSocket()
	{
		const protocol = (window.location.protocol === "http:") ? "ws" : "wss";
		const socket = new WebSocket(`${protocol}://${window.location.host}`, "sd-protocol");

		socket.onmessage = async (event) =>
		{
			const dataString = await new Response(event.data).text();
			const data = JSON.parse(dataString);
			switch (data.type)
			{
				case "console":
					Main.signals.console.dispatch(data.message);
					break;
				case "render":
					Main.signals.render.dispatch(data);
					break;
				case "servers":
					Main.signals.servers.dispatch(data.status);
					break;
			}
		};

		socket.onclose = () => this.showModal(<ErrorModal message={"Connection died."}/>);
		socket.onerror = (error) => this.showModal(<ErrorModal message={`Connection error: ${error}`}/>);
	}

	constructor(props: IMainProps)
	{
		super(props);

		this.state = {
			modals: [],
			renders: [] as IRenderStatus[],
			activePanel: "overview"
		};

		Main.initSocket();

		Main.instance = this;
		window["Main"] = this;
	}

	public componentDidMount = async () =>
	{
		const rendersRaw = await fetch('list');
		const renders = await rendersRaw.json();
		this.setState({renders});

		Main.signals.render.add(this.onRenderEvent);
	};

	public componentWillUnmount(): void
	{
		Main.signals.render.remove(this.onRenderEvent);
	}

	private onRenderEvent = (data: { event: string, render: IRenderStatus }) =>
	{
		let renders: IRenderStatus[] = [...this.state.renders];
		if (data.event === "change")
		{
			renders = renders.filter(render => render.renderId !== data.render.renderId);
		}
		renders.push(data.render);
		this.setState({renders});
	};

	public static showModal = (modal: React.ReactNode): void =>
	{
		Main.instance.setState(state => ({
			modals: [...state.modals, modal]
		}));
	};

	public static hideModal = (): void =>
	{
		Main.instance.setState(state => ({
			modals: state.modals.slice(0, -1)
		}));
	};

	private selectPanel = (panelId: string) =>
	{
		this.setState({activePanel: panelId});
	};

	public render(): React.ReactNode
	{
		const {modals, activePanel, renders} = this.state;
		return (
			<>
				<Navbar id="header">
					<Container>
						<h1 className="pull-left">ASYNTH Render Server</h1>
						<Nav variant="pills" defaultActiveKey={activePanel} onSelect={id => this.selectPanel(id)}>
							{["renders", "tests", "stats", "servers", "overview"].map(panel =>
								<Nav.Item>
									<Nav.Link eventKey={panel}>{panel}</Nav.Link>
								</Nav.Item>
							)}
						</Nav>
					</Container>
				</Navbar>
				<Container>
					{activePanel === "renders" && <Renders maxItems={25} renders={renders}/>}
					{activePanel === "tests" && <Tests maxItems={25}/>}
					{activePanel === "stats" && <Stats renders={renders}/>}
					{activePanel === "servers" && <Servers/>}
					{activePanel === "overview" && <Overview renders={renders}/>}
				</Container>
				{modals.map((dialogElement, idx) => <div key={idx}>{dialogElement}</div>)}
			</>
		);
	}
}
