import * as React       from "react";
import {ModalContainer} from "../ModalContainer";

export interface IAlertModalProps
{
	message: string;
}

export class ErrorModal extends React.PureComponent<IAlertModalProps>
{
	public render(): React.ReactNode
	{
		const {message} = this.props;
		return (
			<ModalContainer title={"Error"} closeButton={false}>{message}</ModalContainer>
		);
	}
}
