import {ChartData}              from "chart.js";
import {ChangeEvent, FormEvent} from "react";
import * as React               from "react";
import {Bar}                    from "react-chartjs-2";

import {IRenderStatus} from "../../common/utils";

interface IStatsProps
{
	renders: IRenderStatus[];
}

interface IStatsStates
{
	providerFilter: string;
	minDate: string;
	maxDate: string;
	selectedMinDate: string;
	selectedMaxDate: string;
}

export class Stats extends React.PureComponent<IStatsProps, IStatsStates>
{
	private readonly serverCost = 3.06; // USD per hour, p3.2xlarge instances in us-east-1

	// Note: render licence gets down to 0.80$ per hour between 500 and 1000 hours monthly,
	// and even cheaper after that, but we don't render enough for this discount to take effect
	private readonly licenceCost = 1.00; // USD per hour

	constructor(props: IStatsProps)
	{
		super(props);
		this.state = this.getState("all");
	}

	private get providers(): Array<{ name: string, count: number }>
	{
		const counts = {};
		for (const render of this.props.renders)
		{
			const provider = render.provider ?? render.userKey;
			counts[provider] = 1 + (counts[provider] || 0);
		}

		const output = [];
		for (const [name, count] of Object.entries(counts))
		{
			output.push({name, count});
		}
		return output.sort((a, b) => b.count - a.count);
	}

	private readonly onProviderFilterChange = (event: FormEvent) =>
	{
		this.setState(this.getState((event.target as HTMLInputElement).value));
	};

	private getProviderFilteredRenders(providerFilter: string): IRenderStatus[]
	{
		let renders = this.props.renders;
		if (providerFilter !== "all")
		{
			renders = renders.filter(render => (render.provider ?? render.userKey) === providerFilter);
		}
		return renders;
	}

	private get filteredRenders(): IRenderStatus[]
	{
		const {providerFilter, selectedMinDate, selectedMaxDate} = this.state;
		return this.getProviderFilteredRenders(providerFilter).filter(render =>
			this.toDate(render.created) >= selectedMinDate &&
			this.toDate(render.created) <= selectedMaxDate
		);
	}

	private get chartData(): ChartData<"bar", number[]>
	{
		const data = {};
		this.filteredRenders.map(render => render.created).forEach(d =>
		{
			const key = this.toDate(d);
			if (!data[key])
			{
				data[key] = 0;
			}
			data[key]++;
		});
		const sorted = Object.keys(data).sort((a, b) => a.localeCompare(b)).reduce((result, key) =>
		{
			result[key] = data[key];
			return result;
		}, {});
		return {
			labels: Object.keys(sorted),
			datasets: [{
				data: Object.keys(sorted).map(key => sorted[key]),
				backgroundColor: 'rgba(0, 87, 130, 0.2)',
				borderColor: 'rgba(0, 87, 130)',
				borderWidth: 1
			}]
		};
	}

	private getState(providerFilter: string): IStatsStates
	{
		const renders = this.getProviderFilteredRenders(providerFilter);

		const timestamps = renders.map(render => render.created).sort();
		if (timestamps.length === 0)
		{
			timestamps.push(new Date().toISOString());
		}

		const minDate = this.toDate(timestamps[0]);
		const maxDate = this.toDate(timestamps[timestamps.length - 1]);
		return {
			providerFilter,
			minDate,
			maxDate,
			selectedMinDate: minDate,
			selectedMaxDate: maxDate
		};
	}

	private sum(values: number[]): number
	{
		return values.reduce((a, b) => a + b, 0);
	}

	private getExportTime(renders: IRenderStatus[]): number
	{
		return this.sum(renders.map(render => render.exportTime || 0));
	}

	private getRenderTime(renders: IRenderStatus[]): number
	{
		return this.sum(renders.map(render => render.renderTime || 0));
	}

	// Servers are billed on a per-second basis, but if a server is started, at least 1 minute is billed
	private getExportCost(renders: IRenderStatus[]): number
	{
		renders = renders.filter(render => render.exportTime);
		const exportTimeAWS = this.sum(renders.map(render => Math.max(60, render.exportTime)));
		return exportTimeAWS / 3600 * this.serverCost;
	}

	// Servers are billed on a per-second basis, but if a server is started, at least 1 minute is billed
	// Licence is billed per every started minute
	private getRenderCost(renders: IRenderStatus[]): number
	{
		renders = renders.filter(render => render.renderTime);
		const renderTimeAWS = this.sum(renders.map(render => Math.max(60, render.renderTime))); // [s]
		const serverCost = renderTimeAWS / 3600 * this.serverCost;
		const renderTimeRS = this.sum(renders.map(render => Math.ceil(render.renderTime / 60))); // [min]
		const licenceCost = renderTimeRS / 60 * this.licenceCost;
		return serverCost + licenceCost;
	}

	private getTotalCost(renders: IRenderStatus[]): number
	{
		return this.getExportCost(renders) + this.getRenderCost(renders);
	}

	private getCostPerRender(renders: IRenderStatus[]): number
	{
		return this.getTotalCost(renders) / renders.length;
	}

	private timeFormat(seconds: number): string
	{
		const days = Math.floor(seconds / 3600 / 24);
		seconds %= 3600 * 24;
		const hours = Math.floor(seconds / 3600);
		seconds %= 3600;
		const minutes = Math.floor(seconds / 60);
		seconds %= 60;

		const pad = (x) => x < 10 ? "0" + x : x;

		if (days)
		{
			return `${days} days, ${pad(hours)} hrs ${pad(minutes)} min`;
		}
		if (hours)
		{
			return `${hours} hrs ${pad(minutes)} min`;
		}
		if (minutes)
		{
			return `${minutes} min ${pad(seconds)} sec`;
		}
		if (seconds)
		{
			return seconds + " seconds";
		}
		return "-";
	}

	private moneyFormat(usd: number): string
	{
		if (!usd)
		{
			return "-";
		}
		return usd.toFixed(2) + " USD";
	}

	private getUsers(renders: IRenderStatus[]): string
	{
		const counts = {};
		for (const render of renders)
		{
			let user = render.userKey;

			// old version stored app specific user keys instead of email addresses, so some mapping is needed
			if (user === "5d95f03a4f77dcfbb4efd8b8cffbfcd4bcdee578" || user === "lofasz" || user === "matyas-test")
			{
				user = "matyas@asynth.com";
			}
			else if (user === "c50b304255af6148d39386995d239b9e69896578")
			{
				user = "tatiana@asynth.com";
			}
			else if (user === "a7c27194bcdb6705164b7362857a3af6f29c0adb")
			{
				user = "baris2@asynth.com";
			}
			else if (user === "3efab51a52937017eb318af0557cb4a63d9284b6")
			{
				user = "peter.paskovits@gmail.com";
			}
			counts[user] = 1 + (counts[user] || 0);
		}

		const data = [];
		for (const [name, count] of Object.entries(counts))
		{
			data.push({name, count});
		}
		return data
			.sort((a, b) => b.count - a.count)
			.map(d => `${d.name} (${d.count})`)
			.slice(0, 20)
			.join(", ");
	}

	private toDate(timestamp: string): string
	{
		return timestamp.split("T")[0];
	}

	private readonly updateMinDate = (event: ChangeEvent<HTMLInputElement>): void =>
	{
		this.setState({selectedMinDate: event.target.value});
	};

	private readonly updateMaxDate = (event: ChangeEvent<HTMLInputElement>): void =>
	{
		this.setState({selectedMaxDate: event.target.value});
	};

	public render()
	{
		const {providerFilter, minDate, maxDate, selectedMinDate, selectedMaxDate} = this.state;
		const allRenders = this.props.renders;
		const renders = this.filteredRenders;
		return (
			<div id="stats">
				<div onChange={this.onProviderFilterChange}>
					<input type="radio" name="whitelabel" id="all" value="all" checked={providerFilter === "all"}/>
					<label htmlFor="all">all ({allRenders.length})</label>
					{this.providers.map(pr => <>
						<input type="radio" name="whitelabel" id={pr.name} value={pr.name}
						       checked={pr.name === providerFilter}/>
						<label htmlFor={pr.name}>{pr.name} ({pr.count})</label>
					</>)}
				</div>
				<div className="timepicker">
					<div>
						From
						<input type="date" value={selectedMinDate}
						       min={minDate} max={maxDate} onChange={this.updateMinDate}/>
						to
						<input type="date" value={selectedMaxDate}
						       min={minDate} max={maxDate} onChange={this.updateMaxDate}/>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<h5>{providerFilter === "all" ? "All renders" : providerFilter}</h5>
							<div className="spacer-md"/>
							<h4>{renders.filter(render => !render.sdvr).length} renders</h4>
							<p>+ {renders.filter(render => render.sdvr).length} SDVR exports</p>
							<div className="spacer-md"/>
							<p>{renders.filter(render => render.status === "done").length} done</p>
							<p>{renders.filter(render => render.status === "error").length} failed</p>
							<p>{renders.filter(render => render.status !== "error" && render.status !== "done").length} active</p>
						</div>
						<div className="col-md-6">
							<div className="spacer-md"/>
							<p>Total export time: <strong>{this.timeFormat(this.getExportTime(renders))}</strong></p>
							<p>Total render time: <strong>{this.timeFormat(this.getRenderTime(renders))}</strong></p>
							<p>Total export cost: <strong>{this.moneyFormat(this.getExportCost(renders))}</strong></p>
							<p>Total render cost: <strong>{this.moneyFormat(this.getRenderCost(renders))}</strong></p>
							<div className="spacer-md"/>
							<p>Total cost: <strong>{this.moneyFormat(this.getTotalCost(renders))}</strong></p>
							<p>Total cost per
								render: <strong>{this.moneyFormat(this.getCostPerRender(renders))}</strong>
							</p>
						</div>
					</div>
					<div className="spacer-md"/>
					<Bar data={this.chartData} options={{plugins: {legend: {display: false}}}}/>
					<div className="spacer-md"/>
					<p><strong>Most active users:</strong> {this.getUsers(renders)}</p>
				</div>
			</div>
		);
	}
}