import * as React         from "react";
import { Doughnut } from 'react-chartjs-2';

interface ITestChartProps
{
	log: string;
}
interface ITestChartStates
{
	times: number[];
}

const template = {
	labels: ['waiting', 'export', 'waiting', 'render'],
	datasets: [
		{
			data: null,
			backgroundColor: [
				'rgba(255, 99, 132, 0.2)',
				'rgba(54, 162, 235, 0.2)',
				'rgba(255, 99, 132, 0.2)',
				'rgba(75, 192, 192, 0.2)'
			],
			borderColor: [
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(75, 192, 192, 1)'
			]
		},
	],
};

export class TestChart extends React.PureComponent<ITestChartProps, ITestChartStates>
{
	private getSeconds(dataset, idx)
	{
		return Math.round((dataset[idx].date - dataset[idx - 1].date) / 1000);
	}

	private formatTime(time: number)
	{
		return new Date(time * 1000).toISOString().substring(11, 19);
	}

	public constructor(props)
	{
		super(props);

		const msgToKeep = [
			"Data received",
			"Starting GLTF export",
			"GLTF export done",
			"Connected to host",
			"Render done"
		];
		const data = this.props.log.split('\r\n').filter(x=>x).map(l=>{
			const sp = l.split(' ');
			const date = new Date(sp[0] + " " + sp[1]);
			const msg = sp.splice(2).join(" ");
			return {date, msg};
		}).filter(l => msgToKeep.find(msg => l.msg.startsWith(msg)));

		const waitForExport = this.getSeconds(data, 1);
		const exporting = this.getSeconds(data, 2);
		const waitForRender = this.getSeconds(data, 3);
		const rendering = this.getSeconds(data, 4);

		this.state = {
			times: [waitForExport, exporting, waitForRender, rendering]
		};
	}

	public render()
	{
		const {times} = this.state;
		const data = JSON.parse(JSON.stringify(template));
		data.datasets[0].data = times;
		return <div>
			<table className="table-sm">
				<tr>
					<td>Wait for export</td>
					<td>{this.formatTime(times[0])}</td>
				</tr>
				<tr>
					<td>Exporting</td>
					<td>{this.formatTime(times[1])}</td>
				</tr>
				<tr>
					<td>Wait for render</td>
					<td>{this.formatTime(times[2])}</td>
				</tr>
				<tr>
					<td>Rendering</td>
					<td>{this.formatTime(times[3])}</td>
				</tr>
				<tr>
					<td><strong>Total</strong></td>
					<td><strong>{this.formatTime(times[0]+times[1]+times[2]+times[3])}</strong></td>
				</tr>
			</table>
			<Doughnut data={data} options={{plugins: {legend: {display: false}}}}/>
		</div>;
	}
}
