import * as React         from "react";
import {Container, Table} from "react-bootstrap";
import Pagination         from "react-js-pagination";

import {E2ETest, E2ETestSuite} from "../../common/utils";
import {TestDetails}           from "./TestDetails";

interface ITestsProps
{
	maxItems: number;
}

interface ITestsStates
{
	tests: E2ETestSuite[];
	activePage: number;
	activeTests: number[];
}

export class Tests extends React.PureComponent<ITestsProps, ITestsStates>
{
	public state = {
		tests: [] as E2ETestSuite[],
		activePage: 1,
		activeTests: []
	};

	public componentDidMount = async () =>
	{
		const testsRaw = await fetch("e2e/list");
		const tests = await testsRaw.json();
		this.setState({tests});
	};

	private createButton(test: E2ETest)
	{
		let variant = "info";
		switch (test.status)
		{
			case "done":
				variant = "success";
				break;
			case "error":
				variant = "danger";
				break;
		}
		return <span className={`test-case badge badge-${variant}`}>{test.label}</span>;
	}

	private handlePageChange = (activePage: number) =>
	{
		this.setState({
			activePage,
			activeTests: []
		});
	};

	private showDetails = (idx: number) =>
	{
		const tests = this.state.activeTests;
		if (tests.includes(idx))
		{
			this.setState({activeTests: tests.filter(t => t !== idx)});
		}
		else
		{
			this.setState({activeTests: [...tests, idx]});
		}
	};

	public render()
	{
		const {tests, activePage, activeTests} = this.state;
		const {maxItems} = this.props;
		const paginationNeeded = tests.length > maxItems;
		let start = 0;
		let end = maxItems;
		if (paginationNeeded)
		{
			start = (activePage - 1) * maxItems;
			end = Math.min(tests.length, activePage * maxItems);
		}
		const shownTests = paginationNeeded ? tests.slice(start, end) : tests;
		return <>
			{paginationNeeded && maxItems > 1 && <>
                <Container className="container-flex">
                    <h4>E2E test results</h4>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={maxItems}
                        totalItemsCount={tests.length}
                        pageRangeDisplayed={5}
                        prevPageText="<"
                        nextPageText=">"
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={this.handlePageChange.bind(this)}
                    />
                </Container>
                <div className="spacer"/>
            </>}
			<Table striped hover={maxItems > 1}>
				{maxItems > 1 && <thead className="thead-dark">
                <tr>
                    <th style={{"width": "20%"}}>Timestamp</th>
                    <th style={{"width": "60%"}}>Tests</th>
                    <th style={{"width": "20%"}}>Details</th>
                </tr>
                </thead>}
				<tbody>
				{shownTests.map((suite, idx) => <>
					<tr key={idx}>
						{maxItems === 1 && <td>
                            <strong>Latest E2E test result</strong>
                        </td>}
						<td>{new Date(+suite.timestamp).toISOString().split(".")[0].split("T").join(" ")}</td>
						<td>{suite.tests.map(this.createButton)}</td>
						<td>
							<span className="link" onClick={() => this.showDetails(idx)}>{activeTests.includes(idx) ? "hide" : "show"}</span>
						</td>
					</tr>
					{activeTests.includes(idx) && <>
						<tr style={{display:"none"}}><td colSpan={4}></td></tr>
						<tr><td colSpan={4} style={{border:"none"}}><Container className="container-flex">
							{suite.tests.map((test, i) =>
								<TestDetails suite={suite} idx={i}/>
							)}
                        </Container></td></tr>
					</>}
				</>)}
				{shownTests.length === 0 && <tr>
                    <td colSpan={4}>Nothing to show...</td>
                </tr>}
				</tbody>
			</Table>
		</>;
	}

}
