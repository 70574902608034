import * as React      from "react";
import {IRenderStatus} from "../../common/utils";
import {Renders}       from "./Renders";
import {Servers}       from "./Servers";
import { Tests }       from "./Tests";

interface IOverviewProps
{
	renders: IRenderStatus[];
}

export class Overview extends React.PureComponent<IOverviewProps>
{
	public render()
	{
		const {renders} = this.props;
		return (
			<>
				<Tests maxItems={1}/>
				<div className="spacer" />
				<Renders maxItems={6} renders={renders}/>
				<div className="spacer-lg" />
				<Servers summary={true}/>
			</>
		);
	}
}
